/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-03-24 15:03:14 */

const Keys = {
  adult: 'adult',
  adult_plural: 'adult_plural',
  child: 'child',
  child_plural: 'child_plural',
  groupTours: 'groupTours',
  sonnenHits: 'sonnenHits',
  destinationsLabel: 'destinationsLabel',
  destinationsPlaceholder: 'destinationsPlaceholder',
  search: 'search',
  dates: 'dates',
  insteadOfFrom: 'insteadOfFrom',
  nowFrom: 'nowFrom',
  perPerson: 'perPerson',
  goToOffer: 'goToOffer',
  travel: 'travel',
  travel_plural: 'travel_plural',
  noResultsFound: 'noResultsFound',
  searchError: 'searchError'
}; export default Keys;