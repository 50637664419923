import { Channel } from '@ibe/services';
import ProductItemListEvent from './productItemListEvent';
import PackageItemSelectEvent from './packageItemSelectEvent';
import ProductItemSelectEvent from './productItemSelectEvent';

export const productItemListEvent = new Channel<ProductItemListEvent>(
  ProductItemListEvent,
  'product-item-list'
);
export const packageItemSelectEvent = new Channel<PackageItemSelectEvent>(
  PackageItemSelectEvent,
  'package-item-select'
);
export const productItemSelectEvent = new Channel<ProductItemSelectEvent>(
  ProductItemSelectEvent,
  'product-item-select'
);

export enum TrackingEvents {
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_TRAVEL_DETAILS = 'add_travel_details',
  ADD_ADDITIONAL_SERVICES = 'add_additional_services',
  CHECKOUT_STEP = 'checkout_step',
  PURCHASE = 'purchase'
}
