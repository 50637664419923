import React, { JSX } from 'react';
import fallback from '../Translations/generated/search-content.de.json';
import { PackageParams, useTranslation } from '@ibe/components';
import { SessionStorage } from '@ibe/services';
import ProductsSearchForm, { ListType, OnPackagesLoaded } from './ProductsSearchForm';
import { ApiProductCard } from '../../api';
import { productSearchSessionKey } from '../Config/config';
import { ApiGetPackagesResponse } from '@ibe/api';

export type SessionStorageType = {
  search: string | PackageParams;
  list: ListType;
};

const sessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);

const isProductCards = (
  list: (ApiGetPackagesResponse | null) | ApiProductCard[]
): list is ApiProductCard[] => {
  return Array.isArray(list);
};

const Search = ({
  redirectUrl,
  sessionProp
}: {
  redirectUrl?: string;
  sessionProp?: SessionStorageType;
}): JSX.Element => {
  const { t } = useTranslation('search-content', fallback);

  const handlePackagesLoaded: OnPackagesLoaded = (
    search: string | PackageParams,
    list: (ApiGetPackagesResponse | null) | ApiProductCard[]
  ): void => {
    if (
      (isProductCards(list) && list.length > 0) ||
      (!isProductCards(list) && !!list && list?.packages?.length > 0)
    ) {
      sessionStorage.set({ search, list });
      if (!!redirectUrl) {
        location.href = redirectUrl;
      }
    }
  };

  return (
    <ProductsSearchForm
      onPackagesLoaded={(
        search: string | PackageParams,
        list: (ApiGetPackagesResponse | null) | ApiProductCard[]
      ) => handlePackagesLoaded(search, list)}
      search={sessionProp?.search as string}
    />
  );
};

export default Search;
