import React, {
  useState,
  ChangeEvent,
  useMemo,
  useCallback,
  FormEvent,
  useEffect,
  JSX
} from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Keys from '../Translations/generated/search-content.de.json.keys';
import fallback from '../Translations/generated/search-content.de.json';
import { PackageParams, useTranslation, Warning } from '@ibe/components';
import useSearchApi from '../Util/useSearchApi';
import { ApiProductCard, ApiProductSearchRequestFromJSON } from '../../api';
import { ApiGetPackagesResponse } from '@ibe/api';
import { LoggerFactory, SessionStorage } from '@ibe/services';
import { SessionStorageType } from './Search';
import { productSearchSessionKey } from '../Config/config';

const logger = LoggerFactory.get('SearchForm');

export type FormType = 'PRODUCT' | 'PACKAGE';
export type ListType = (ApiGetPackagesResponse | null) | ApiProductCard[];
export type OnPackagesLoaded = (
  search: string | PackageParams,
  list: ListType,
  formType?: FormType
) => void;

const ProductsSearchForm = ({
  search,
  onPackagesLoaded
}: {
  search?: string;
  onPackagesLoaded: OnPackagesLoaded;
}): JSX.Element => {
  const { t } = useTranslation('search-content', fallback);
  const searchApi = useSearchApi();
  const [noResults, setNoResults] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (!!search) {
      setValue(search);
    }
  }, [search]);

  const sessionStorage = new SessionStorage<SessionStorageType>(productSearchSessionKey);

  const isValid = useMemo(() => {
    return value?.length > 2;
  }, [value]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();

      setNoResults(false);
      setError(false);
      if (isValid) {
        try {
          const result = await searchApi.searchProducts(
            ApiProductSearchRequestFromJSON({ searchTerm: value })
          );
          if (!!result?.items && result?.items?.length > 0) {
            onPackagesLoaded(value, result.items);
            sessionStorage.set({ search: value, list: result.items });
          } else {
            onPackagesLoaded(value, null);
            setNoResults(true);
          }
        } catch (err) {
          setError(true);
          logger.error(err);
        }
      }
    },
    [value, isValid, onPackagesLoaded]
  );

  return (
    <div className="thg-search">
      <form className="search-form" onSubmit={handleSubmit}>
        <Row>
          <Col sm={8} className="mb-4 mb-sm-0">
            <Label className="search-form__label font-weight-medium">
              {t(Keys.destinationsLabel)}
            </Label>
            <div className="position-relative">
              <FontAwesomeIcon className="search-form__icon" icon={faGlobe} />
              <Input
                className="search-form__input"
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                placeholder={t(Keys.destinationsPlaceholder)}
              />
            </div>
          </Col>
          <Col sm={4} className="d-flex align-items-end">
            <Button
              color="primary"
              type="submit"
              className="search-form__submit"
              disabled={!isValid}
            >
              {t(Keys.search)}
            </Button>
          </Col>
        </Row>
        {(noResults || error) && (
          <Row className="mt-4">
            <Col>
              {noResults && <Warning message={t(Keys.noResultsFound)} />}
              {error && <Warning message={t(Keys.searchError)} />}
            </Col>
          </Row>
        )}
      </form>
    </div>
  );
};

export default ProductsSearchForm;
