import { createElement } from 'react';
import ReactDOM from 'react-dom/client';
import { IDomFactory } from 'react-habitat';

export class CustomHabitatFactory implements IDomFactory {
  inject(
    //eslint-disable-next-line
    module: any,
    props: {},
    target: Element
  ) {
    if (target) {
      const root = ReactDOM.createRoot(target);
      root.render(createElement(module, props || {}));
    } else {
      console.warn('Target is null or undefined');
    }
  }

  dispose(target: Element) {
    if (target) {
      const root = ReactDOM.createRoot(target);
      root.unmount();
    }
  }
}
